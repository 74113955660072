.job-application-form {
    &-inner {
        background-color: $secondary;
        color: $white;
        border-radius: $border-radius;
        @include spacing(padding, all, map-get($spacers, 3x));
    }

    &-header {
        text-align: center;
        @include spacing(margin, bottom, map-get($spacers, 3x));

        .headline .headline-content {
            @include use-section(md);
            margin-bottom: map-get($map: $spacers, $key: sm);
        }

        a {
            color: $primary;
        }
    }

    .form-control {
        border-color: $white;
        color: $white;
    }

    .btn {
        background-color: transparent;
        border-color: $white;
        color: $white;

        &:hover {
            background-color: transparent;
            border-color: $primary;
            color: $primary;
        }
    }
}
