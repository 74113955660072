.newsletter-card {
    background-color: $secondary;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border-radius: $border-radius;
    overflow: hidden;

    &-content {
        grid-column: 1;
        grid-row: 1;
        background-color: $secondary;
        display: flex;
        flex-direction: column;
        gap: map-get($map: $spacers, $key: md);
        @include rfs(2.5rem, padding);

        .headline .headline-content {
            color: $primary;
            @include rfs(1.625rem);
            font-weight: 600;
            margin-bottom: 0;
        }

        .text {
            @include use-body(sm, $white);
        }

        div[lastWidth="lastWidth"] {
            margin-top: map-get($map: $spacers, $key: sm);
        }
    }

    &-form {
        margin-top: map-get($map: $spacers, $key: md);

        input,
        label,
        textarea,
        input:focus,
        label:focus,
        textarea:focus {
            color: $white;
            border-color: $white;
        }

        textarea.form-control {
            min-height: auto;
        }

        button {
            background-color: transparent;
            border-color: $white;
            color: $white;
            margin-top: map-get($map: $spacers, $key: lg);

            &:hover {
                background-color: transparent;
                border-color: $primary;
                color: $primary;
            }
        }
    }

    &-image {
        .image,
        img {
            height: 100%;
            width: 100%;
        }
    }

    @include media-breakpoint-down(xl) {
        .newsletter-info {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
        }
    }

    @include media-breakpoint-down(lg) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        padding: 0;

        &-content {
            grid-row: 1;
            padding-right: unset;
            padding: map-get($map: $spacers, $key: 2x);
        }

        &-form {
            grid-row: 2;
        }

        &-image {
            .image,
            img {
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
            }
        }
    }
}
