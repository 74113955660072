.header {
    background-color: $white;
    transition: background-color $transition-length ease-in-out;

    &.sticky-top {
        position: sticky;
        z-index: 1020;
    }

    &-logo {
        transform-origin: left;
        transition: transform $transition-length ease-in-out;
    }

    &.scrolled {
        .header-logo {
            transform: scale(0.8);
        }

        .top-bar {
            grid-template-rows: 0fr;

            &-inner {
                padding-block: 0;
            }
        }

        #main-nav .nav-link {
            --bs-nav-link-padding-y: #{map-get($map: $spacers, $key: md)};
        }
    }
}
