.content-info-tile {
    display: grid;
    border-radius: $border-radius;
    overflow: hidden;

    &--right {
        grid-template-columns: 1fr 350px;

        .content-info-tile-content {
            grid-column: 1;
        }

        .content-info-tile-media {
            grid-column: 2;
        }
    }

    &--left {
        grid-template-columns: 350px 1fr;
        .content-info-tile-content {
            grid-column: 2;
        }

        .content-info-tile-media {
            grid-column: 1;
        }
    }

    &-content {
        flex: 1;
        @include spacing(padding, all, 7rem);
        display: flex;
        flex-direction: column;
        grid-row: 1;

        .headline {
            margin-bottom: map-get($map: $spacers, $key: 2x);

            .headline-content {
                @include use-headline(md);
            }
        }

        .text {
            flex: 1;
            @include use-body(sm);
        }

        .link {
            margin-top: map-get($map: $spacers, $key: 2x);
        }
    }

    &-media {
        position: relative;
        grid-row: 1;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    @include media-breakpoint-down(lg) {
        &--right,
        &--left {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 400px;

            .content-info-tile-content {
                grid-row: 1;
                grid-column: 1;
            }

            .content-info-tile-media {
                grid-row: 2;
                grid-column: 1;
            }
        }
    }
}

.bg-white {
    .content-info-tile {
        &-content {
            background-color: $secondary;
        }

        .text,
        .headline .headline-content,
        .link a {
            color: $white;
        }

        .link a::after {
            background-color: $white;
        }
    }
}

.bg-gray,
.bg-secondary,
.bg-primary {
    .content-info-tile {
        &-content {
            background-color: $white;
        }

        .text {
            color: $body-color;
        }

        .headline .headline-content {
            color: $black;
        }

        .link {
            a {
                color: $primary-dark;
            }
        }
    }
}
