.hero-teaser {
    @include hero-base(teaser);

    &-content {
        align-self: center;

        .container-inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            justify-content: center;
            gap: map-get($map: $spacers, $key: 2x);
        }

        .headline {
            .headline-content {
                @include rfs(5rem);
                font-family: "Open Sans";
                font-weight: 700;
                text-transform: uppercase;
                line-height: 1;
            }
        }

        .text {
            width: clamp(275px, 40%, 600px);
            margin-inline: auto;
        }
    }
}
