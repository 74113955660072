//* ### Hero ###
// SM
$hero-sm-font-family: "Playfair Display";
$hero-sm-font-size: 2.25rem;
$hero-sm-font-style: normal;
$hero-sm-font-weight: 700;
$hero-sm-line-height: 110%;

// MD
$hero-md-font-family: "Playfair Display";
$hero-md-font-size: 2.5rem;
$hero-md-font-style: normal;
$hero-md-font-weight: 700;
$hero-md-line-height: 110%; /* 4.4rem */
$hero-md-text-transform: none;

// LG
$hero-lg-font-family: "Playfair Display";
$hero-lg-font-size: 3rem;
$hero-lg-font-style: normal;
$hero-lg-font-weight: 700;
$hero-lg-line-height: 110%;
$hero-lg-text-transform: none;

//* ### Section ###
// SM
$section-sm-font-family: "Open Sans";
$section-sm-font-size: 1.75rem;
$section-sm-font-style: normal;
$section-sm-font-weight: 700;
$section-sm-line-height: 155%; /* 4.65rem */
$section-sm-text-transform: none;

// MD
$section-md-font-family: "Open Sans";
$section-md-font-size: 2rem;
$section-md-font-style: normal;
$section-md-font-weight: 700;
$section-md-line-height: 155%; /* 4.65rem */
$section-md-text-transform: none;

// LG
$section-lg-font-family: "Open Sans";
$section-lg-font-size: 2.25rem;
$section-lg-font-style: normal;
$section-lg-font-weight: 700;
$section-lg-line-height: 155%; /* 4.65rem */
$section-lg-text-transform: none;

//* ### Headline ###
// SM
$headline-sm-font-family: "Open Sans";
$headline-sm-font-size: 1.25rem;
$headline-sm-font-style: normal;
$headline-sm-font-weight: 700;
$headline-sm-line-height: 140%;

// MD
$headline-md-font-family: "Open Sans";
$headline-md-font-size: 1.75rem;
$headline-md-font-style: normal;
$headline-md-font-weight: 700;
$headline-md-line-height: 140%;

// LG
$headline-lg-font-family: "Open Sans";
$headline-lg-font-size: 2.25rem;
$headline-lg-font-style: normal;
$headline-lg-font-weight: 700;
$headline-lg-line-height: 140%;

//* ### Body ###
// SM
$body-sm-font-family: "Open Sans";
$body-sm-font-size: 0.85rem;
$body-sm-font-style: normal;
$body-sm-font-weight: 400;
$body-sm-line-height: 1.65;

// MD
$body-md-font-family: "Open Sans";
$body-md-font-size: 1rem;
$body-md-font-style: normal;
$body-md-font-weight: 400;
$body-md-line-height: 1.65;

// LG
$body-lg-font-family: "Open Sans";
$body-lg-font-size: 1.25rem;
$body-lg-font-style: normal;
$body-lg-font-weight: 400;
$body-lg-line-height: 1.65;

//* ### Button Label ###
// SM
$button-label-sm-font-family: "Open Sans";
$button-label-sm-font-size: 0.75rem;
$button-label-sm-font-style: normal;
$button-label-sm-font-weight: 500;
$button-label-sm-line-height: normal;

// MD
$button-label-md-font-family: "Open Sans";
$button-label-md-font-size: 1rem;
$button-label-md-font-style: normal;
$button-label-md-font-weight: 500;
$button-label-md-line-height: normal;

// LG
$button-label-lg-font-family: "Open Sans";
$button-label-lg-font-size: 1.25rem;
$button-label-lg-font-style: normal;
$button-label-lg-font-weight: 500;
$button-label-lg-line-height: normal;

//* ### Link ###
$link-font-family: "Open Sans";
$link-font-size: 0.9rem;
$link-font-style: normal;
$link-font-weight: 500;
$link-line-height: 1.5625rem;

//* ### Nav Link ###
// SM
$nav-link-sm-font-family: "Open Sans";
$nav-link-sm-font-size: 0.8rem;
$nav-link-sm-font-style: normal;
$nav-link-sm-font-weight: 400;
$nav-link-sm-line-height: normal;

// MD
$nav-link-md-font-family: "Open Sans";
$nav-link-md-font-size: 1rem;
$nav-link-md-font-style: normal;
$nav-link-md-font-weight: 400;
$nav-link-md-line-height: normal;
