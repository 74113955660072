.gallery {
    .image,
    .image img {
        @include image-fit(cover);
    }
}

.container,
.container-boxes {
    .gallery {
        border-radius: $border-radius;
        overflow: hidden;
    }
}
