.article {
    h1 {
        font-size: 2rem;
    }

    h2 {
        font-size: 1.75rem;
    }

    h3 {
        font-size: 1.5rem;
    }

    h4 {
        font-size: 1.25rem;
    }

    h5 {
        font-size: 1rem;
    }

    h6 {
        font-size: 0.75rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: "Open Sans", sans-serif;
        color: $headline-color;
        margin-top: map-get($map: $spacers, $key: 2x);
    }
}
