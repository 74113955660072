//** Custom Variables **//

// Colors
$category-colors: (
    'gray': #404040,
    'green': #00ac66,
    'lime': #b8fe72,
    'orange': #fd8427,
);

$border-radius: 10px;

// Cookie Conset
.cmppreviewroot {
    display: flex;
}
