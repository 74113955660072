.content-text-image {
    display: grid;
    border-radius: $border-radius;
    overflow: hidden;

    &--right {
        grid-template-columns: 1fr 2fr;

        .content-text-image-content {
            grid-column: 1;
        }

        .content-text-image-media {
            grid-column: 2;
        }
    }

    &--left {
        grid-template-columns: 2fr 1fr;
        .content-text-image-content {
            grid-column: 2;
        }

        .content-text-image-media {
            grid-column: 1;
        }
    }

    &-content {
        flex: 1;
        @include spacing(padding, all, map-get($map: $spacers, $key: 3x));
        display: flex;
        flex-direction: column;
        grid-row: 1;

        .headline {
            margin-bottom: map-get($map: $spacers, $key: 2x);

            .headline-content {
                @include use-headline(sm, $secondary);
            }
        }

        .text {
            flex: 1;
            @include use-body(sm, $black);
        }

        .link {
            margin-top: map-get($map: $spacers, $key: 2x);
        }
    }

    &-media {
        grid-row: 1;

        .image {
            height: 100%;

            img {
                @include image-fit(cover);
            }
        }
    }

    @include media-breakpoint-down(lg) {
        &--right,
        &--left {
            grid-template-columns: 1fr;
            grid-template-rows: auto auto;
        }

        &--right {
            .content-text-image-content {
                grid-column: 1;
                grid-row: 1;
            }

            .content-text-image-media {
                grid-column: 1;
                grid-row: 2;
            }
        }

        &--left {
            .content-text-image-content {
                grid-column: 1;
                grid-row: 1;
            }

            .content-text-image-media {
                grid-column: 1;
                grid-row: 2;
            }
        }
    }
}

.bg-white,
.bg-primary {
    .content-text-image {
        &-content {
            background-color: $gray;
        }
    }
}

.bg-gray,
.bg-secondary {
    .content-text-image {
        &-content {
            background-color: $white;
        }
    }
}
