#main-nav {
    .container-inner {
        flex: 1;
        display: flex;
        flex-wrap: inherit;
        align-items: center;
        justify-content: space-between;
    }

    &.navbar {
        --bs-navbar-padding-y: 0;
    }

    .navbar-toggler {
        border-radius: 0;
        border: 0;
        outline: 0;
        line-height: normal;
        color: $secondary;
    }

    .nav-item {
        &:last-child {
            .nav-link {
                padding-inline-end: 0;
            }
        }
    }

    .nav-link {
        --bs-navbar-nav-link-padding-x: 1.5rem;
        --bs-nav-link-padding-y: #{map-get($map: $spacers, $key: 2x)};
        transition: padding $transition-length ease-in-out;
    }

    .nav-link,
    .dropdown-link {
        @include use-nav-link(md, $body-color);

        &.active,
        &:hover {
            @include use-nav-link(md, $secondary);
        }
    }

    .dropdown-menu {
        background-color: $white;
        transition: background-color $transition-length ease-in-out;
    }

    .dropdown:hover .dropdown-menu {
        display: block;
    }

    .dropdown-item {
        --bs-dropdown-link-active-bg: transparent;
    }

    .dropdown-menu.show-mobile {
        @include media-breakpoint-down(xl) {
            display: block !important;
        }
    }

    @include media-breakpoint-down(xl) {
        .dropdown-menu {
            background-color: transparent;
        }

        .nav-link,
        .nav-link:hover,
        .dropdown-link,
        .dropdown-link:hover {
            color: $white;

            &.active {
                color: $primary;
            }
        }

        .nav-link {
            font-weight: 700;
            padding-bottom: 0;

            &.active {
                font-weight: 700;
            }
        }
    }
}
