.btn {
    --bs-btn-border-width: 1px;
    @include use-button-label(md);
    @include spacing(padding, y, $btn-padding-y);
    @include spacing(padding, x, $btn-padding-x);
}

// Variant solid
@each $color, $value in $theme-colors {
    .btn-#{$color} {
        @if $color == "primary" {
            @include button-variant(
                $background: $primary,
                $color: $white,
                $hover-color: $white,
                $border: transparent,
                $hover-background: $secondary,
                $hover-border: transparent,
                $active-background: $primary,
                $active-border: transparent,
                $active-color: $primary
            );
        } @else if $color == "secondary" {
            @include button-variant(
                $background: $secondary,
                $color: $white,
                $hover-color: $gray-1000,
                $border: transparent,
                $hover-background: $primary,
                $hover-border: transparent,
                $active-background: $secondary,
                $active-border: transparent,
                $active-color: $white
            );
        }
    }
}

// Variant outline
@each $color, $value in $theme-colors {
    .btn-outline-#{$color} {
        @include button-outline-variant(
            $value,
            $color-hover: $primary,
            $active-background: transparent,
            $active-border: $primary,
            $active-color: $primary
        );
    }
}

.link-icon {
    @include use-link($body-color);
    position: relative;
    display: inline-flex;
    align-items: center;
    padding-right: 3rem;
    text-decoration: none;
    border: 0;
    outline: 0;
    background-color: transparent;

    &::after,
    &::before {
        content: "";
        position: absolute;
        transition: all 0.5s ease-in-out;
    }

    // Line
    &::after {
        width: 20px;
        height: 1px;
        background-color: $black;
        right: 10px;
    }

    // Circle
    &::before {
        height: 20px;
        width: 20px;
        border-radius: 50rem;
        border: solid 1px $primary-dark;
        background-color: transparent;
        right: 0;
    }

    &:hover {
        &::before {
            transform: translateX(-10px);
        }

        &::after {
            transform: translateX(10px);
        }
    }
}
