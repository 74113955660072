.job-application-teaser {
    background-color: $secondary;
    @include spacing(padding, all, map-get($map: $spacers, $key: 5x));
    border-radius: $border-radius;

    &-text {
        .headline .headline-content {
            @include use-headline(md, $white);
            margin-bottom: map-get($map: $spacers, $key: md);
        }
    }

    &-link {
        margin-top: map-get($map: $spacers, $key: lg);

        .btn {
            background-color: transparent;
            border-color: $white;
            color: $white;

            &:hover {
                background-color: transparent;
                border-color: $primary;
                color: $primary;
            }
        }
    }
}
