//** Bootstrap Variables **//

// Base Colors
$white: #fff;
$gray-100: #f7f8f9;
$gray-200: #d8d9da;
$gray-300: #babbbc;
$gray-400: #9d9e9f;
$gray-500: #818283;
$gray-600: #666667;
$gray-700: #666667;
$gray-800: #4c4d4d;
$gray-900: #333435;
$gray-1000: #1d1d1e;
$black: #000;

$primary: #b8fe72;
$primary-dark: #6caf31;
$secondary: #0b3b36;
$background: #f2f2f2;
$gray: #f2f2f2;
$anthrazit: #404040;

$custom-theme-colors: (
    "background": $background,
    "gray": $gray,
    "black": $black,
    "white": $white,
);

// Image Position Values
$imagePositions: (
    left: left,
    leftTop: left top,
    leftBottom: left bottom,
    right: right,
    rightTop: right top,
    rightBottom: right bottom,
    top: top,
    middle: center,
    bottom: bottom,
);

// Container Max Widths
$container-max-widths: (
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
);

$container-inner-padding-x: 5rem;

// Spacers
$spacer: 1rem;
$spacers: (
    0: 0,
    xs: $spacer * 0.25,
    sm: $spacer * 0.5,
    md: $spacer,
    lg: $spacer * 1.5,
    2x: $spacer * 2,
    3x: $spacer * 3,
    4x: $spacer * 4,
    5x: $spacer * 5,
    6x: $spacer * 6,
    7x: $spacer * 7,
    8x: $spacer * 8,
    9x: $spacer * 9,
    10x: $spacer * 10,

    section: 7rem,
);

// CSS Grid
$enable-cssgrid: false;

// Body
$body-color: $black;

// Buttons
$btn-line-height: 1.2;
$btn-font-size: 0.875rem;
$btn-font-weight: 900;
$btn-border-width: 2px;
$btn-padding-y: 0.8125rem;
$btn-padding-x: 1.25rem;

$btn-color: $white;
$btn-hover-color: $white;
$btn-border-radius: 0.625rem;

// Links
$link-color: $body-color;

// Navbar
$navbar-toggler-padding-y: 0;
$navbar-toggler-padding-x: 0;
$navbar-toggler-focus-width: 0;
$navbar-light-color: $white;
$navbar-light-hover-color: $primary;
$navbar-light-active-color: $primary;

// Navbar Brand
$navbar-brand-padding-y: 0.5rem;

// Border Radius
$border-radius: 0.625rem;
$border-radius-sm: 1.25rem;
$border-radius-lg: 2.5rem;
$border-radius-xl: 5rem;
$border-radius-xxl: 10rem;

// Font Family
$font-family-sans-serif:
    "Open Sans",
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    "Noto Sans",
    "Liberation Sans",
    Arial,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji";

$h1-font-size: 2.75rem;
$h2-font-size: 2.25rem;
$h3-font-size: 2rem;
$h4-font-size: 1.75rem;
$h5-font-size: 1.5rem;

$h6-font-size: 1.25rem;

$font-size-base: 1rem;
$line-height-base: 1.5;

$headings-font-weight: 400;
$headings-line-height: 1.2;
$headline-color: $secondary;

$rfs-base-value: 0.85rem;

// Input
$input-bg: $white;
$input-border-color: $white;
$input-placeholder-color: $black;

// Accordion
$accordion-button-active-bg: transparent;
$accordion-border-color: #c7c7c7;
$accordion-button-focus-border-color: transaperent;
$accordion-button-focus-box-shadow: transaperent;
$accordion-border-radius: 0;
$accordion-bg: $white;
$accordion-button-padding-x: 0;

// Dropdown
$dropdown-border-radius: 0;
$dropdown-item-padding-y: $spacer * 0.5;
$dropdown-item-padding-x: $spacer * 1.5;
$dropdown-link-active-color: $primary;
$dropdown-min-width: auto;
$dropdown-border-width: 0;
$dropdown-spacer: 0;
$dropdown-bg: transparent;
$dropdown-color: $white;
$dropdown-link-hover-bg: transparent;

// Transition
$transition-length: 0.5s;

// Offcanvas
$offcanvas-bg-color: $secondary;
$offcanvas-color: $white;
$btn-close-color: $white;
$btn-close-opacity: 1;

// Tabs
$nav-pills-link-active-color: $primary;
$nav-pills-link-active-bg: transparent;
