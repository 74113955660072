.nav-pills {
    gap: map-get($map: $spacers, $key: lg);

    .nav-link {
        --bs-nav-link-padding-x: 0;
        @include use-nav-link(sm);
    }
}

.tab-content {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    .tab-pane {
        grid-column: 1;
        grid-row: 1;
        display: block;
        visibility: hidden;

        &.active {
            visibility: visible;
        }
    }
}
