.footer-main {
    color: $white;

    &-content {
        @include spacing(margin, top, map-get($map: $spacers, $key: 4x));
        @include spacing(margin, bottom, map-get($map: $spacers, $key: 4x));
    }

    .footer-category-navigation {
        @include spacing(padding, top, map-get($map: $spacers, $key: 2x));
        @include spacing(padding, bottom, map-get($map: $spacers, $key: 2x));
        background-color: $background;

        &-content {
            .nav {
                justify-content: space-between;
            }

            .nav-meta {
                list-style: none;
                padding-left: 0;
            }
            .nav-link {
                padding-left: 0;
            }

            .nav-link-parent {
                @include rfs(1rem);
                color: $gray-600;
                font-weight: 600;
                margin-bottom: map-get($map: $spacers, $key: sm);
                padding-left: 0;
                &:hover {
                    font-weight: 600;
                    @include rfs(1rem);
                }
            }
        }
    }

    .footer-meta-nav {
        background-color: $gray;
        @include rfs(3rem, padding-block);

        .nav {
            justify-content: space-between;

            .nav-item {
                padding-left: 0;
            }
        }

        .nav-meta {
            list-style: none;
            padding-left: 0;
        }

        .nav-link-parent {
            @include rfs(1rem);
            color: $anthrazit;
            font-weight: 600;
            margin-bottom: map-get($map: $spacers, $key: sm);

            &:hover {
                font-weight: 600;
                @include rfs(1rem);
            }
        }

        .nav-link:not(.nav-link-parent) {
            @include font-size(0.875rem);
        }
    }

    .footer-content {
        @include spacing(padding, top, map-get($map: $spacers, $key: 2x));
        @include spacing(padding, bottom, map-get($map: $spacers, $key: 2x));
        &.has-background-image {
            position: relative;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;

            > .image {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .container,
            .container-fluid,
            .container-inner {
                position: relative;
                z-index: 1;
            }
        }
    }

    .footer-bottom {
        background-color: $secondary;
        padding-block: map-get($map: $spacers, $key: md);

        .container-inner {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
        }

        &-copyright {
            @include use-nav-link(sm, $white);
        }

        &-imprint,
        &-quicklinks {
            .nav-link {
                @include use-nav-link(sm, $white);
            }
        }

        &-quicklinks {
            margin-left: auto;
        }

        @include media-breakpoint-up(md) {
            &-quicklinks {
                margin-left: 0;
            }
        }

        @include media-breakpoint-down(md) {
            &-copyright {
                order: 3;
            }

            &-imprint {
                order: 1;
            }

            &-quicklinks {
                order: 2;
            }
        }
    }
}
