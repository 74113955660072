.product-detail {
    --spacing: #{map-get($map: $spacers, $key: 2x)};
    --button-size: 40px;

    &-content {
        &-gallery {
            .text-content {
                height: 100%;
                padding: var(--spacing);
                display: flex;
                flex-direction: column;
                gap: 30px;

                .headline-content {
                    margin-bottom: 0;
                    @include rfs(1.375rem);
                    font-weight: 700;
                }

                .subheadline-content {
                    font-weight: 700;
                    @include rfs(1rem);
                }
                .text-content {
                    flex: 1;
                    @include use-body(sm);
                }

                .link {
                    line-height: 200%;
                    font-size: $font-size-sm;
                    margin-top: auto;
                }

                .link-content {
                    @include margin-hover;
                }
            }
        }
    }
    .product-detail-content-gallery {
        &.bg-primary {
            .headline-content,
            .text-content {
                color: $white;
            }

            .swiper-button-prev,
            .swiper-button-next {
                color: $secondary;
                border-color: $secondary;
            }

            .link-content,
            .link i {
                color: $secondary;
            }
        }

        &.bg-gray {
            .headline-content,
            .text-content {
                color: $primary;
            }

            .swiper-button-prev,
            .swiper-button-next {
                color: $primary-dark;
                border-color: $primary-dark;
            }

            .link-content,
            .link i {
                color: $primary-dark;
            }

            .swiper-slide-logo img {
                filter: grayscale(100%);
            }
        }
    }
    .swiper {
        height: 100%;

        .image,
        img {
            height: 100%;
            width: 100%;
        }

        img {
            object-fit: cover;
        }

        &-buttons {
            position: absolute;
            bottom: var(--spacing);
            left: var(--spacing);
            display: flex;
            gap: map-get($map: $spacers, $key: 2x);

            .swiper-button-prev,
            .swiper-button-next {
                font-size: 1rem;
                cursor: pointer;
                z-index: 99;
                height: var(--button-size);
                width: var(--button-size);
                display: grid;
                place-items: center;
                border-radius: 50rem;
                border: solid 2px;
                transition:
                    color $transition-length ease-in-out,
                    border-color $transition-length ease-in-out;
                transform: none;

                &:hover {
                    color: $white;
                    border-color: $white;
                }
            }
        }
    }
}
