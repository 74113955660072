.location-map {
    background-color: $background;

    &-inner {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    &-content {
        padding-right: calc((1 / 12 * 100%) + #{$grid-gutter-width});
        @include spacing(padding, y, map-get($spacers, section));

        @each $container, $width in $container-max-widths {
            @include media-breakpoint-up($container) {
                margin-left: calc(
                    100% - ($width / 2) + ($container-padding-x / 2) +
                        $container-inner-padding-x
                );
            }
        }

        .location-swiper {
            max-width: 500px;
            width: 100%;
        }

        .slide-inner {
            display: flex;
            flex-direction: column;
            gap: map-get($map: $spacers, $key: md);
        }

        .location-name,
        .location-description {
            margin-bottom: 0;
        }

        .location-address::before {
            padding-right: map-get($map: $spacers, $key: xs);
        }

        .location-address,
        .location-description {
            @include use-body(sm, $black);
        }

        .location-name {
            @include rfs(1rem);
            color: $black;
            font-weight: 600;
        }
    }

    &-map {
        max-width: 50vw;

        .cmppreviewroot {
            height: 100%;
            justify-content: center;
            align-items: center;
        }
    }

    @include media-breakpoint-down(md) {
        &-inner {
            grid-template-columns: 1fr;
            grid-auto-rows: min-content;
        }

        &-content {
            max-width: 100vw;
            padding-right: calc((1 / 12 * 100%) + #{$grid-gutter-width});
            padding-left: calc((1 / 12 * 100%) + #{$grid-gutter-width});
            margin-left: 0;
        }

        &-map {
            max-width: 100vw;
            height: 400px;
        }
    }

    @include media-breakpoint-down(sm) {
        &-content {
            padding-right: map-get($spacers, md);
            padding-left: map-get($spacers, md);
        }
    }
}
