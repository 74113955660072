.thanks {
    main {
        display: flex;
    }

    &-container {
        flex: 1;
        display: flex;
        align-items: center;
    }

    .inner {
        border-radius: $border-radius;
        @include spacing(padding, all, map-get($spacers, 3x));

        .headline .headline-content {
            @include use-section(md, $white);
        }

        .text {
            @include use-body(md, $white);
        }
    }

    .footer-main {
        background-image: none;
    }
}
