.card-slider {
    &-item {
        border-radius: 0.625rem;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        &-content {
            flex: 1;
            padding-inline: map-get($map: $spacers, $key: 2x);
            padding-top: map-get($map: $spacers, $key: 2x);
            display: flex;
            flex-direction: column;
            gap: 0.75rem;

            .headline .headline-content {
                @include use-headline(sm, $secondary);
            }

            p {
                @include use-body(sm, $body-color);

                &:first-of-type {
                    font-weight: 500;
                }
            }
        }

        &-link {
            padding: map-get($map: $spacers, $key: 2x);
        }
    }
}

// Parent depended styles
.bg-white {
    .card-slider {
        &-item {
            &-content,
            &-link {
                background-color: $gray;
            }
        }
    }
}

.bg-gray,
.bg-secondary,
.bg-primary {
    .card-slider {
        &-item {
            &-content,
            &-link {
                background-color: $white;
            }
        }
    }
}

.card-slider,
.blog-slider {
    .swiper {
        overflow: visible;
    }
}
