//* Mixins
%header-headline {
    .headline .headline-content {
        @include spacing(margin, bottom, map-get($map: $spacers, $key: 3x));
        @include use-section(md, $headline-color);
    }
}

//* Styles
.job-detail {
    &-info {
        display: flex;
        gap: map-get($map: $spacers, $key: 2x);

        &-location,
        &-type {
            white-space: nowrap;
            @include use-body(sm);
        }

        i {
            color: $primary;
            padding-right: map-get($map: $spacers, $key: xs);
            font-weight: 700;
        }
    }

    &-header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: $spacer;

        &-headline {
            flex: 1;
            @include use-section(md, $headline-color);
            margin-bottom: 0;
            max-width: 70%;
        }
    }

    &-content {
        @include spacing(padding, y, map-get($map: $spacers, $key: section));

        &-grid {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: $spacer;

            &-item {
                .text {
                    @include use-body(sm);
                }
            }
        }

        .headline .headline-content {
            @include use-headline(md);
            @include spacing(margin, bottom, 1.875rem);
        }
    }

    &-benefits {
        &-header {
            @extend %header-headline;
        }

        @include spacing(padding, y, map-get($map: $spacers, $key: section));
        background-color: $background;
    }

    &-steps {
        &-header {
            @extend %header-headline;
        }

        @include spacing(padding, y, map-get($map: $spacers, $key: section));
        background-color: $white;
    }

    &-applay {
        &-header {
            .headline .headline-content {
                @include spacing(margin, bottom, map-get($map: $spacers, $key: 1x));
                @include use-section(sm, $headline-color);
                text-align: center;
            }
        }

        .text {
            @include spacing(margin, bottom, map-get($map: $spacers, $key: 4x));
            text-align: center;
        }
    }

    //* Media queries
    @include media-breakpoint-down(lg) {
        &-info {
            gap: map-get($map: $spacers, $key: md);
            flex-wrap: wrap;
            @include spacing(margin, top, map-get($map: $spacers, $key: 2x));
        }

        &-header {
            flex-direction: column;
            align-items: flex-start;
            gap: map-get($map: $spacers, $key: md);
        }

        &-content {
            &-grid {
                grid-template-columns: 1fr;
                gap: map-get($map: $spacers, $key: lg);
            }
        }
    }
}
