.card-shades {
    overflow-x: scroll;
    scrollbar-width: none;

    &-inner {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: $spacer;
    }

    &-item {
        @include spacing(padding, y, map-get($map: $spacers, $key: 2x));
        @include spacing(padding, x, map-get($map: $spacers, $key: md));
        border-radius: $border-radius;
        min-width: 200px;

        @for $i from 1 through 5 {
            &:nth-child(#{$i}) {
                background-color: rgba($primary, $i * 0.2);
            }
        }

        &-header {
            .headline .headline-content {
                @include use-headline(sm, $white);
                margin-bottom: map-get($map: $spacers, $key: lg);
            }
        }

        &-content {
            &-title {
                font-size: 0.8rem;
                font-weight: 700;
                margin-bottom: map-get($map: $spacers, $key: md);
            }

            &-text {
                @include use-body(sm, $white);
            }
        }
    }

    @include media-breakpoint-down(md) {
        &-inner {
            width: auto;
        }
    }

    @include media-breakpoint-down(md) {
        margin-left: calc($spacer * -1);
        margin-right: calc($spacer * -1);

        &-item {
            &:first-child {
                margin-left: $spacer;
            }

            &:last-child {
                margin-right: $spacer;
            }
        }
    }
}
