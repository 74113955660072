.contact-card {
    background-color: $secondary;
    padding: map-get($map: $spacers, $key: 5x);
    display: grid;
    grid-template-columns:
        calc(45% - map-get($spacers, 2x))
        calc(55% - map-get($spacers, 2x));
    gap: map-get($map: $spacers, $key: 4x);
    border-radius: $border-radius;

    &-content {
        color: $white;
        display: flex;
        flex-direction: column;

        .headline .headline-content {
            @include use-section(sm, $white);
            margin-bottom: 0;
        }

        .text-cta {
            color: $primary;
            font-family: "Open Sans";
            font-size: 20px;
            font-weight: 600;
            margin-bottom: map-get($map: $spacers, $key: lg);
        }

        .contact-info {
            margin-top: map-get($map: $spacers, $key: lg);
            display: grid;
            grid-template-columns: auto 1fr;
            gap: map-get($map: $spacers, $key: 2x);
            @include use-body(sm, $white);

            &-address,
            &-phone,
            &-email {
                padding-left: 25px;
                position: relative;
                white-space: nowrap;

                a {
                    color: $white;
                }

                i {
                    position: absolute;
                    left: 0;
                    top: 4px;
                }
            }
        }
    }

    &-form {
        input,
        label,
        textarea {
            color: $white;
            border-color: $white;

            &:focus {
                color: $white;
            }
        }

        textarea.form-control {
            min-height: auto;
        }

        button {
            background-color: transparent;
            border-color: $white;
            color: $white;

            &:hover {
                background-color: transparent;
                border-color: $primary;
                color: $primary;
            }
        }
    }

    @include media-breakpoint-down(xl) {
        .contact-info {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
            gap: map-get($map: $spacers, $key: md);
        }
    }

    @include media-breakpoint-down(lg) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        gap: map-get($map: $spacers, $key: 2x);
        padding: map-get($map: $spacers, $key: 3x);

        &-content {
            grid-row: 1;
        }

        &-form {
            grid-row: 2;
        }
    }

    @include media-breakpoint-down(sm) {
        padding: map-get($map: $spacers, $key: lg);
    }
}
