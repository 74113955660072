body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    main {
        flex-grow: 1;
        overflow-x: hidden;
    }
}

a,
a[href^='mailto:'],
a[href^='tel:'] {
    text-decoration: none;
}

p {
    &:only-child,
    &:last-child {
        margin-bottom: 0;
    }
}

ul {
    padding-left: map-get($map: $spacers, $key: md);
}

* {
    scroll-margin-top: 50px;
}
