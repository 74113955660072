.headline {
    &-accent {
        color: $primary-dark;
    }

    &-content {
        &.sm {
            @include use-headline(sm, $headline-color);
            margin-bottom: map-get($map: $spacers, $key: md);
        }

        &.md {
            @include use-headline(md, $headline-color);
            margin-bottom: map-get($map: $spacers, $key: md);
        }

        &.lg {
            @include use-headline(lg, $headline-color);
            margin-bottom: map-get($map: $spacers, $key: md);
        }

        &.section {
            @include use-section(md, $headline-color);
            margin-bottom: map-get($map: $spacers, $key: 2x);
        }
    }
}
