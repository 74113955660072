@mixin use-body($key: md, $color: null) {
    $typo-map: map-get($body, $key);
    font-family: map-get($typo-map, font-family);
    @include rfs(map-get($typo-map, font-size));
    font-weight: map-get($typo-map, font-weight);
    line-height: map-get($typo-map, line-height);

    @if $color {
        color: $color;
    }
}

@mixin use-link($color: null) {
    font-family: map-get($link, font-family);
    @include rfs(map-get($link, font-size));
    font-weight: map-get($link, font-weight);
    line-height: map-get($link, line-height);

    @if $color {
        color: $color;
    }
}

@mixin use-nav-link($key: md, $color: null) {
    $typo-map: map-get($nav-link, $key);
    font-family: map-get($typo-map, font-family);
    @include rfs(map-get($typo-map, font-size));
    font-weight: map-get($typo-map, font-weight);
    line-height: map-get($typo-map, line-height);

    @if $color {
        color: $color;
    }
}

@mixin use-hero($key: md, $color: null) {
    $typo-map: map-get($hero, $key);
    font-family: map-get($typo-map, font-family);
    @include rfs(map-get($typo-map, font-size));
    font-weight: map-get($typo-map, font-weight);
    line-height: map-get($typo-map, line-height);
    text-transform: map-get($typo-map, text-transform);

    @if $color {
        color: $color;
    }
}

@mixin use-section($key: md, $color: null) {
    $typo-map: map-get($section, $key);
    font-family: map-get($typo-map, font-family);
    @include rfs(map-get($typo-map, font-size));
    font-weight: map-get($typo-map, font-weight);
    line-height: map-get($typo-map, line-height);
    text-transform: map-get($typo-map, text-transform);

    @if $color {
        color: $color;
    }
}

@mixin use-headline($key: md, $color: null) {
    $typo-map: map-get($headline, $key);
    font-family: map-get($typo-map, font-family);
    @include rfs(map-get($typo-map, font-size));
    font-weight: map-get($typo-map, font-weight);
    line-height: map-get($typo-map, line-height);

    @if $color {
        color: $color;
    }
}

@mixin use-button-label($key: md, $color: null) {
    $typo-map: map-get($button-lable, $key);
    font-family: map-get($typo-map, font-family);
    @include rfs(map-get($typo-map, font-size));
    font-weight: map-get($typo-map, font-weight);
    line-height: map-get($typo-map, line-height);

    @if $color {
        color: $color;
    }
}
