.container {
    &-inner {
        @include rfs($container-inner-padding-x, padding-inline);

        @include media-breakpoint-down(md) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &-fluid {
        padding: 0;

        > .container-inner {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
