.pagination {
    &-nav {
        margin: 0;
        padding: 0;
        display: grid;
        grid-template-areas: "prev next";
        gap: 1rem;
        flex: 1;
        justify-content: space-between;

        &-item {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            color: $secondary;
            transition: color $transition-length ease-in-out;

            &.prev {
                grid-area: prev;
            }

            &.next {
                grid-area: next;
            }

            &:hover {
                color: $primary-dark;
            }
        }
    }
}
