.form-newsletter-subscribe {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .card {
        --bs-body-bg: #{$secondary};
        --bs-border-color-translucent: transparent;

        &-body {
            --bs-card-color: #{$white};
            --bs-card-spacer-y: 1.5rem;
            --bs-card-spacer-x: 1.5rem;
        }

        &-title {
            @include use-headline(sm, $primary-dark);
            margin-bottom: map-get($map: $spacers, $key: lg);
        }
    }

    .form-group {
        .form-label {
            margin-top: map-get($map: $spacers, $key: sm);
            margin-bottom: map-get($map: $spacers, $key: xs);
        }

        .form-control {
            border-color: $white;
            color: $white;

            &::placeholder {
                color: $white;
            }
        }

        .form-check-label {
            color: $white;
        }
    }
}
