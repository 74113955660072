.hero-default {
    @include hero-base(default) {
        &-content {
            justify-content: flex-end;
            margin-bottom: clamp(50px, 20vh, 20vh);

            .headline {
                width: clamp(200px, 70vw, 850px);

                .headline-content {
                    @include use-hero(lg, $primary);
                }
            }

            .text {
                @include use-hero(sm, $white);
                width: clamp(200px, 70vw, 900px);
                text-shadow: 0px 0px 1px $body-color;
            }
        }
    }
}
