.top-bar {
    transition: grid-template-rows $transition-length ease-in-out;
    display: grid;
    grid-template-rows: 1fr;
    overflow: hidden;
    background-color: $primary;

    &-inner {
        overflow: hidden;
        padding-block: map-get($map: $spacers, $key: xs);
        transition: padding-block $transition-length ease-in-out;
    }

    &-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    a,
    span {
        @include use-nav-link(sm, $secondary);

        &:hover {
            @include use-nav-link(sm, $body-color);
        }
    }

    &-contact {
        display: flex;
        flex-wrap: nowrap;
        gap: $grid-gutter-width;
    }

    &-links {
        .navbar-nav {
            flex-direction: row;
            gap: map-get($map: $spacers, $key: md);
        }
        .nav-link {
            --bs-nav-link-color: $secondary;
            --bs-nav-link-padding-y: 0;
            @include use-nav-link(sm);
        }
    }

    @include media-breakpoint-down(lg) {
        display: none;
    }
}
