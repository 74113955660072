.brands-banner {
    @include spacing(padding, y, map-get($spacers, 2x));
    background-color: $gray;

    &-headline {
        @include rfs(1.5rem);
        font-weight: 600;
        color: $gray-300;
    }

    &-logos {
        filter: grayscale(100%);
        opacity: 0.5;
        transition:
            filter $transition-length ease-in-out,
            opacity $transition-length ease-in-out;

        &:hover {
            filter: grayscale(0%);
            opacity: 1;
        }
    }

    .swiper-slide {
        justify-content: center;
    }
}
