.slider-image-content {
    --spacing: #{map-get($map: $spacers, $key: 2x)};
    --button-size: 40px;

    border-radius: $border-radius;
    overflow: hidden;

    // Base Styles
    .swiper {
        height: 100%;

        &-content {
            background-color: $secondary;

            .swiper-slide {
                padding: var(--spacing);
                transition: opacity 100ms ease-in-out;
                position: relative;
                z-index: 1;

                .headline-content,
                .text-content {
                    color: $white;
                }

                &-active {
                    z-index: 2;
                }

                &-logo {
                    filter: brightness(0) invert(1);
                    @include spacing(
                        margin,
                        bottom,
                        map-get($map: $spacers, $key: 2x)
                    );
                }

                .slide-inner {
                    display: flex;
                    flex-direction: column;
                    gap: map-get($map: $spacers, $key: lg);

                    .headline-content {
                        margin-bottom: 0;
                        @include rfs(1.375rem);
                        font-weight: 700;
                    }

                    .text-content {
                        flex: 1;
                        @include use-body(sm);
                    }
                }
            }
        }

        &-image {
            .image,
            img {
                height: 100%;
                width: 100%;
            }

            img {
                object-fit: cover;
            }
        }

        &-buttons {
            position: absolute;
            bottom: var(--spacing);
            left: var(--spacing);
            display: flex;
            gap: map-get($map: $spacers, $key: 2x);

            .synced-swiper-button-prev,
            .synced-swiper-button-next {
                font-size: 1rem;
                cursor: pointer;
                z-index: 99;
                height: var(--button-size);
                width: var(--button-size);
                display: grid;
                place-items: center;
                border-radius: 50rem;
                border: solid 2px $primary;
                color: $primary;
                transition:
                    color $transition-length ease-in-out,
                    border-color $transition-length ease-in-out;
                transform: none;

                &:hover {
                    color: $white;
                    border-color: $white;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .swiper {
        &-content {
            .swiper-slide {
                padding: calc(var(--spacing) / 2) !important;
            }
        }
    }
}
