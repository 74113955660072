.content-intro-text {
    display: grid;
    gap: map-get($map: $spacers, $key: 4x);
    grid-template-columns:
        calc(55% - map-get($spacers, 2x))
        calc(45% - map-get($spacers, 2x));

    &-header {
        grid-row: 1;
        grid-column: 1;

        .headline .headline-content {
            @include use-section(md, $headline-color);
        }
    }

    &-content {
        grid-row: 1;
        grid-column: 2;

        display: flex;
        flex-direction: column;
        gap: 1rem;

        .link-icon {
            margin-top: map-get($map: $spacers, $key: md);
        }
    }

    @include media-breakpoint-down(lg) {
        grid-template-columns: 1fr;
        gap: map-get($map: $spacers, $key: md);

        &-header {
            grid-row: 1;
            grid-column: 1;
        }

        &-content {
            grid-row: 2;
            grid-column: 1;
        }
    }
}

.bg-secondary {
    .content-intro-text {
        &-header {
            .headline .headline-content {
                color: $white;
            }
        }

        &-content {
            color: $white;

            .link-icon {
                color: $white;

                &::after {
                    background-color: $white;
                }
            }
        }
    }
}
