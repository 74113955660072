@mixin hero-base($variant: "video") {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    overflow: hidden;

    .hero-#{$variant}-background,
    .hero-#{$variant}-content {
        grid-column: 1;
        grid-row: 1;
    }

    .hero-#{$variant}-background {
        position: relative;
        display: flex;

        .image {
            width: 100%;
        }

        img,
        video {
            @include image-fit(cover);
            object-position: top;
            height: clamp(400px, 90vh, 640px);
        }

        img {
            height: clamp(400px, 80vh, 640px);
        }
    }

    .hero-#{$variant}-content {
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 1;
        color: $white;

        .headline .headline-content {
            margin-bottom: 0;
            // hyphens: auto;
            text-shadow: 0px 0px 1px $body-color;
        }
    }

    @if $variant == "image" or $variant == "video" {
        .hero-#{$variant}-content {
            justify-content: flex-end;
            margin-bottom: clamp(50px, 20vh, 20vh);

            .headline {
                width: clamp(200px, 70vw, 850px);

                .headline-content {
                    @include use-hero(md, $white);
                }
            }
        }
    }

    @content;
}
