.accordion {
    --bs-accordion-bg: transparent;
    --bs-accordion-active-color: #{$primary-dark};

    &-item {
        border-left: 0;
        border-right: 0;
        border-bottom: solid 1px #d8d8d8;
        border-top: solid 1px #d8d8d8;
    }

    &-button {
        @include rfs(1.25rem);
        font-weight: 600;
        color: #404040;

        &:not(.collapsed) {
            box-shadow: none;
        }

        &::after {
            height: 40px;
            width: 40px;
            background-position: center center;
            border-radius: 50rem;
            border: solid 1px #404040;
        }
    }

    &-body {
        padding-inline: 0;
    }
}
