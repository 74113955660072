.blog-list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;

    .category-filter {
        grid-column: 1 / -1;
        grid-row: 1;
    }

    .blogs-container {
        display: flex;
        flex-direction: column;
        gap: map-get($map: $spacers, $key: 2x);

        .blog-card {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: 1fr;
            border-radius: 0.625rem;
            overflow: hidden;
            background-color: $background;

            &-image {
                .image,
                img {
                    height: 100%;
                }
            }

            &-content {
                padding: map-get($map: $spacers, $key: 2x);
                display: flex;
                flex-direction: column;

                .headline .headline-content {
                    @include use-headline(sm, $secondary);
                    margin-bottom: 0.3rem;
                }

                &-subtitle {
                    @include use-body(sm, $primary-dark);
                    margin-bottom: map-get($map: $spacers, $key: md);
                }

                &-text {
                    flex-grow: 1;
                    @include use-body(sm, $body-color);
                }

                &-link {
                    padding: map-get($map: $spacers, $key: 2x);
                    padding-left: 0;
                    padding-bottom: 0;
                    display: flex;
                    justify-content: flex-start;
                }
            }
        }
    }

    .pagination {
        grid-column: 1 / -1;
    }

    @include media-breakpoint-down(lg) {
        .blogs-container {
            .blog-card {
                grid-template-columns: 1fr;
                grid-template-rows: repeat(2, 1fr);
            }
        }
    }
}
