.products-list {
    --card-padding: #{map-get($map: $spacers, $key: md)};

    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-template-rows: repeat(5, min-content);
    gap: 1.5rem;

    .category-filter-container {
        grid-column: 1 / -1;
        grid-row: 1;
    }

    .product-card {
        background-color: $gray;
        border-radius: $border-radius;
        overflow: hidden;
        display: grid;
        flex-direction: column;
        height: 100%;
        grid-row: span 5;
        grid-template-rows: subgrid;
        gap: 0;
        padding: var(--card-padding);

        &-image {
            grid-row: 1;
            margin-inline: calc(var(--card-padding) * -1);
            margin-top: calc(var(--card-padding) * -1);

            img {
                @include image-fit();
            }
        }

        &-manufacturer {
            grid-row: 2;
            text-transform: uppercase;
            margin-top: var(--card-padding);
            margin-bottom: calc(var(--card-padding) / 3);
            color: $primary-dark;
        }

        &-name {
            grid-row: 3;
            @include use-headline(sm);
            margin-bottom: calc(var(--card-padding) * 1.5);

            a {
                color: $secondary;
            }
        }

        &-description {
            grid-row: 4;
            @include use-body(sm, $body-color);
            margin-bottom: calc(var(--card-padding) * 1.5);
        }

        &-link {
            grid-row: 5;
            display: flex;
            justify-content: flex-start;
        }
    }

    .pagination {
        grid-column: 1 / -1;
        justify-content: flex-end;
    }

    @include media-breakpoint-down(xl) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @include media-breakpoint-down(lg) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include media-breakpoint-down(sm) {
        grid-template-columns: 1fr;
    }
}
