.page-teaser-grid {
    .headline {
        @include spacing(margin, bottom, map-get($spacers, "lg"));
    }

    &-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        gap: $spacer;
        border-radius: $border-radius;
        overflow: hidden;
    }

    &-item {
        overflow: hidden;
        :hover {
            img {
                transform: scale(1.05);
            }
        }

        &:nth-child(4n + 1),
        &:nth-child(4n + 4) {
            grid-column: span 2;
        }

        &:nth-child(4n + 2),
        &:nth-child(4n + 3) {
            grid-column: span 1;
        }

        a {
            height: 100%;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
        }

        img {
            transition: transform 0.3s ease;
        }
    }

    &-title {
        z-index: 2;
        grid-column: 1;
        grid-row: 1;
        align-self: flex-end;
        @include rfs(1.125rem);
        line-height: 1;
        font-weight: 600;
        color: $white;
        margin: map-get($map: $spacers, $key: lg);
        padding-left: map-get($map: $spacers, $key: xs);
        border-left: solid 4px;

        @each $name, $value in $category-colors {
            &.#{$name} {
                border-color: $value;
            }
        }
    }

    &-image {
        grid-column: 1;
        grid-row: 1;

        .image,
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

@media (max-width: 768px) {
    .page-teaser-grid {
        &-grid {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
        }

        &-item {
            :hover {
                img,
                .image {
                    transform: scale(1.1);
                }
            }
            &:nth-child(n) {
                grid-column: 1;
            }
        }
    }
}
