.jobs-list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;

    &-container {
        .nav {
            @include spacing(margin, bottom, map-get($map: $spacers, $key: 2x));
        }
    }

    &-item {
        display: flex;
        align-items: center;
        border-bottom: solid 1px $gray-600;
        gap: map-get($map: $spacers, $key: lg);
        padding: map-get($map: $spacers, $key: sm) 0;

        &-title {
            flex: 1;
            @include use-body(md);
        }
    }

    @include media-breakpoint-down(lg) {
        &-item {
            flex-direction: column;
            align-items: flex-start;
            gap: map-get($map: $spacers, $key: md);
            padding: map-get($map: $spacers, $key: lg) 0;

            &-title {
            }
        }
    }
}
