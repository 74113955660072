/**
 * @mixin responsive spacing
 *
 * @description A mixin for applyinng margin & padding in different directions using Bootstraps rfs-fluid-value Function.
 *
 * @param {string} $property - The CSS property to apply like margin or padding.
 * @param {string} $direction - The direction in which to apply the spacing. Possible values: 'all', 'y', 'x', 'top', 'bottom', 'left', 'right'.
 * @param {string} $value - The value of the spacing to apply.
 *
 * @example Usage:
 *   .my-element {
 *     @include spacing('margin', 'all', '20px');
 *   }
 */

@mixin spacing($property, $direction, $value) {
    @if $direction == 'all' {
        #{$property}: $value;
    } @else if $direction == 'y' {
        #{$property}-top: $value;
        #{$property}-bottom: $value;
    } @else if $direction == 'x' {
        #{$property}-left: $value;
        #{$property}-right: $value;
    } @else if $direction == 'top' {
        #{$property}-top: $value;
    } @else if $direction == 'bottom' {
        #{$property}-bottom: $value;
    } @else if $direction == 'left' {
        #{$property}-left: $value;
    } @else if $direction == 'right' {
        #{$property}-right: $value;
    }

    @include media-breakpoint-down(xxl) {
        @if $direction == 'all' {
            #{$property}: rfs-fluid-value($value);
        } @else if $direction == 'y' {
            #{$property}-top: rfs-fluid-value($value);
            #{$property}-bottom: rfs-fluid-value($value);
        } @else if $direction == 'x' {
            #{$property}-left: rfs-fluid-value($value);
            #{$property}-right: rfs-fluid-value($value);
        } @else if $direction == 'top' {
            #{$property}-top: rfs-fluid-value($value);
        } @else if $direction == 'bottom' {
            #{$property}-bottom: rfs-fluid-value($value);
        } @else if $direction == 'left' {
            #{$property}-left: rfs-fluid-value($value);
        } @else if $direction == 'right' {
            #{$property}-right: rfs-fluid-value($value);
        }
    }
}
