.card-icon {
    display: flex;
    height: 100%;
    border-radius: $border-radius;
    @include spacing(padding, y, map-get($map: $spacers, $key: 3x));
    @include spacing(padding, x, map-get($map: $spacers, $key: lg));
    gap: map-get($map: $spacers, $key: 2x);

    //* Variants
    &--top {
        flex-direction: column;
    }

    &--left {
        flex-direction: row;
        align-items: center;
    }

    &--white {
        background-color: $white;
        color: $body-color;
    }

    &--red {
        background-color: $secondary;
        color: $white;
    }

    //* Base Styles
    &-icon {
        background-color: $primary-dark;
        color: $white;
        border-radius: 50rem;
        width: 90px;
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 auto;

        i {
            @include rfs(2.5rem);
        }
    }

    &-content {
        &-text {
            @include use-body(sm);
        }

        ul li {
            padding-left: map-get($map: $spacers, $key: md);
            padding-bottom: map-get($map: $spacers, $key: lg);

            &::marker {
                content: "\f00c";
                font-family: "Font Awesome 5 Pro";
            }
        }
    }

    .headline .headline-content {
        @include use-headline(sm);
    }

    //* Media Queries
    @include media-breakpoint-down(lg) {
        &--top {
            min-height: auto;
        }
    }
}

.bg-white {
    .card-icon {
        background-color: $gray;
    }
}

.bg-gray,
.bg-primary,
.bg-secondary {
    .card-icon {
        background-color: $white;
    }
}
